import {Component, Input, OnInit} from '@angular/core';

// @ts-ignore
@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.css'],
})
export class TileComponent implements OnInit {
  @Input() tileType: string;
  @Input() tileShadow: boolean;


  tileText = '';
  shadowText: string | undefined;

  constructor() {
  }

  ngOnInit(): void {
    this.shadowText = 'noShadow';
    this.tileTextFunc(this.tileType);
    this.shadowFunc(this.tileShadow);
  }

  tileTextFunc(input: string) {
    switch (input) {
      case 'text':
        this.tileText = 'text';
        break;
      case 'chat':
        this.tileText = 'chat';
        break;
      case 'binary':
        this.tileText = 'a or b';
        break;
      case 'more':
        this.tileText = 'more soon';
        break;
      case 'faq':
        this.tileText = 'what is a tag?';
        break;
      default:
        this.tileText = '';
        break;
    }
  }

  shadowFunc(bool: boolean) {
    if (bool) {
      this.shadowText = 'tile-shadow';
    }
    if (!bool) {
      this.shadowText = 'no-tile-shadow';
    }
  }
}
