import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {InitTextComponent} from './components/init-tag/init-text/init-text.component';
import {ScanComponent} from './components/scan/scan.component';
import {LoadTextComponent} from './components/load-tag/load-text/load-text.component';
import {ChooseTagComponent} from './components/init-tag/choose-tag/choose-tag.component';
import {SuccessComponent} from './components/success/success.component';
import {PreviewTextComponent} from './components/preview-tag/preview-text/preview-text.component';
import {FaqComponent} from './components/faq/faq.component';
import {StartComponent} from './components/start/start.component';
import {ImpressumComponent} from './components/impressum/impressum.component';
import {AdminPanelComponent} from './components/admin-panel/admin-panel.component';
import {AdminGuard} from './services/interceptors/admin.guard';


const routes: Routes = [
  // example { path: 'chat/:id/:sId', component: ChatComponent },
  // TODO: maybe rearrange some of the routes.
  {path: 'home', component: HomeComponent}, // TODO: Home isn't really needed anymore.
  {path: 'new-text', component: InitTextComponent},
  {path: 'scan', component: ScanComponent},
  {path: 'preview-text', component: PreviewTextComponent},
  {path: 'load-text', component: LoadTextComponent},
  {path: 'choose-tag', component: ChooseTagComponent},
  {path: 'success', component: SuccessComponent},
  {path: 'faq', component: FaqComponent},
  {path: 'start', component: StartComponent},
  {path: 'admin', component: AdminPanelComponent, canActivate: [AdminGuard]},
  {path: 'impressum', component: ImpressumComponent},
  {path: '', redirectTo: '/start', pathMatch: 'full'},
  {path: ':tagId', component: ScanComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
