<div class="row">
  <div class="col-12">
    <h4>
      Welcome to the TAGGIE-Demo application.
    </h4>
  </div>
  <div class="col-12 text-center">
    <a [routerLink]="['/aQGy-ewT6-6RLH']" class="btn-standard">Example: Scan a Tag</a>
    <div>&nbsp;</div>
    <a [routerLink]="['/r0qv-ytQS-ptWN']" class="btn-standard">Single Use: Create new Tag</a>
  </div>
  <div>
    <button mat-raised-button color="primary"></button>

  </div>
</div>
