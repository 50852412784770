<div class="row d-flex justify-content-center">
  <div class="col-12 justify-content-center">
    <h1 class="start-title mt-4 mb-4 display-4">Claim your space. </h1>
  </div>
  <div class="col-12 justify-content-center ">
    <p class="paragraph">
      Scan the sticker with your phone and open the portal to new spaces.
      Taggie connects the digital cosmos with the physical world.
    </p>
  </div>

  <div class="col-12 justify-content-center mt-2">
    <h6 class="start-sub-title">The following tags are available:</h6>
  </div>
  <div class="col-12">
    <div class="col-4 offset-4">
      <app-tile [tileType]="textTile" [tileShadow]=false>
      </app-tile>
    </div>
  </div>
  <div class="col-12 mt-3">
    <div class="col-10 offset-1">
      <p class="paragraph">
        A "text" tag is the simplest form of a Taggie. You can write a short message, a poem or a short story on it.
        You have a total of 200 characters at your disposal.
        Therefore, choose your content wisely.
        People who scan your Taggie can only read it and not edit it.
      </p>
    </div>
  </div>

  <div class="col-12 justify-content-center">
    <h6 class="start-sub-title mb-2 mt-3">Much more is coming:</h6>
  </div>

  <div class="col-3">
    <app-tile [tileType]="chatTile" [tileShadow]=false></app-tile>
  </div>

  <div class="col-3">
    <app-tile [tileType]="binaryTile" [tileShadow]=false></app-tile>
  </div>

  <!-- TODO: Remove, I added this for easier testing. -->
  <div class="col-12 mt-3 text-center">
    <h4>See the application in action:</h4>
    <div class="col-12 justify-content-center">
      <a [routerLink]="['/aQGy-ewT6-6RLH']" class="btn-standard">Example: Scan a Tag</a>
      <div>&nbsp;</div>
      <a [routerLink]="['/r0qv-ytQS-ptWN']" class="btn-standard">Single Use: Create new Tag</a>
    </div>
  </div>


  <div class="col-12 grey-box">
    <div>
      <h6 class="start-sub-title py-2">How it works:</h6>
    </div>
    <div>
      <h4>
        1. Get a Taggie.
      </h4>
      <p>
        First you need a Taggie. At the moment we are in a testing phase, therefore we don't sell any sticker yet.
        But in the future they will be available for purchase.
      </p>
    </div>
    <div>
      <h4>
        2. Initialize it.
      </h4>
      <p>
        Now you just have to scan your Taggie and decide what type of tag you wan't it to be.
        From here, we will guide you through the process of publishing your Taggie.
        Finally, you only have to place the tag.
      </p>
    </div>
    <div>
      <h4>
        3. See what happens.
      </h4>
      <p>
        After you have published your Taggie, everybody can scan and interact with it.
        In this stage it's up to the users to decide what happens.
      </p>
    </div>
  </div>

  <div class="col-12 text-center mt-2">
    <div>
      <h5 class="start-sub-title">About Us:</h5>
    </div>
    <div>
      <p class="paragraph">
        We're a small team of students from Zurich, Switzerland working hard to make Taggie happen and accessible for
        everyone.
        If you want to give feedback, aks a question or just want to chat, reach out to us by Mail:
        <a href="mailto:taggiesocial@gmail.com">Send Mail</a>
      </p>
    </div>
  </div>


  <div class="col-12 text-center">
    <span class="made-in-zurich-tile">
      <span class="tile-inner">
        Made in Zurich with ❤
      </span>
    </span>
  </div>

</div>

