import {userRole} from './userRole.enum';

export class User {
  name: string;
  password: string;
  role: userRole;

  constructor(username: string, password: string, role: userRole) {
    this.name = username;
    this.password = password;
    this.role = role;
  }
}
