import { Component, OnInit } from '@angular/core';
import { TextService } from '../../../services/tags/text.service';
import { Text } from '../../../dataobjects/tags/text';
import { LsKeys } from '../../utility/ls-keys.enum';

@Component({
  selector: 'app-load-text',
  templateUrl: './load-text.component.html',
  styleUrls: ['./load-text.component.css']
})
export class LoadTextComponent implements OnInit {

  tag: Text;
  views: number;
  createdAt: Date;

  initialView: boolean; // this value is used to not increase the view when the user loads the page again.

  errorOccurred: boolean;
  errorMessage: string;
  loading: boolean;

  constructor(public textService: TextService) {
    this.views = 0;
    this.tag = textService.emptyTag();
    this.tag.tagId = localStorage.getItem(LsKeys.TagId);

    // 'JSON.parse' parses string to boolean. null if it is not set
    this.initialView = JSON.parse(localStorage.getItem(LsKeys.InitialView)) == null;
    this.loading = true;
  }

  ngOnInit() {
    if (this.tag.tagId === null) {
      this.errorOccurred = true;
      this.loading = false;
      this.errorMessage = 'No tagId provided.';
    } else {
      this.loadTag();
    }
  }

  loadTag(): void {
    this.textService.getTag(this.tag.tagId, this.initialView).subscribe((data) => {
        this.tag.data = data.data == null ? new Text() : data.data;
        this.tag.occupied = data.occupied;
        this.views = data.statistic.views;
        this.createdAt = new Date(data.occTimestamp);
        this.loading = false;
        localStorage.setItem(LsKeys.InitialView, 'false');
      }, (error) => {                              // Error callback
        this.errorOccurred = true;
        this.loading = false;
        this.errorMessage = ' "' + error.error.message + '"';
      }
    );
  }
}
