<div class="row mx-0 text-center">
  <div class="col-12 text-center mt-2">
    <app-error [errorMessage]="errorMessage" [show]="errorOccurred"></app-error>
  </div>
  <div class="col-12 text-center">
    <h4 class="taggie-title mt-3 mb-2">This is what your tag will look like:</h4>
    <div class="text-tag">
      <div class="tile-space">
        <div [ngClass]="{
          'very-short-text': (tag.data.text.length > 0),
          'short-text': (tag.data.text.length > 10),
          'short-medium-text': (tag.data.text.length > 20),
          'medium-text': (tag.data.text.length > 50),
          'long-text': (tag.data.text.length > 80),
          'very-long-text': (tag.data.text.length > 120)}">{{tag.data.text}}</div>
      </div>
      </div>
  </div>
  <div class="col-12 text-center">
    <a (click)="send()" class="btn-standard">Send Tag!</a> <!-- TODO: use a swipe movement. -->
    <br>
    <a [routerLink]="['/new-text']" class="btn-standard">Back</a>
  </div>
</div>
<app-loading [active]="loading"></app-loading>
