<div class="row mx-0">
  <div class="col-12 text-center">
    <h4 class="taggie-title my-3">Good choice, let's make a text tag:</h4>
  </div>
  <div class="col-4 offset-4 mb-2">
    <app-tile [tileType]="textTile" [tileShadow]=false></app-tile>
  </div>

  <form class="col-12 text-center">
    <div class="row mx-0 text-tag-input">
      <div class="input-field col-12"> <!-- input-field is materialize.css class-->
        <mat-form-field class="input-field">
          <textarea matInput id="text-tag-input1" class="input-area" name="text-tag-input" [(ngModel)]="textAreaInput"
                    [maxLength]="200"></textarea>
          <mat-label [ngClass]="{'active': (textAreaInput != '') }">Enter your text... ({{ textAreaInput.length }} /
            200)
          </mat-label>
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="col-12 text-center mt-4">
    <a [routerLink]="['/preview-text']" (click)="save()" [class.disabled]="(textAreaInput == '')"
       [attr.href]="(textAreaInput == '') ? null : '#'" class="btn-standard">Continue</a>
  </div>
  <div class="col-12 text-center">
    <a [routerLink]="['/choose-tag']" (click)="save()" class="btn-standard">Back</a>
  </div>
</div>
