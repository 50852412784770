import {Component, OnInit} from '@angular/core';



@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css'],
})
export class StartComponent implements OnInit {
  textTile = 'text';
  chatTile = 'chat';
  binaryTile = 'binary';
  constructor() { }

  ngOnInit() {
  }

}
