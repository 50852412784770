<div class="row">
  <div class="col-12 text-center">
    <h2 mat-dialog-title>Admin Login</h2>
  </div>
  <div class="col-12 text-center">
    <mat-dialog-content class="px-0 mx-0">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <app-alert></app-alert>
        <div class="mb-3 mx-3">
          <mat-form-field class="w-100">
            <input matInput type="text" formControlName="username" placeholder="Username">
            <mat-error *ngIf="username.invalid && submitted">Username is required</mat-error>
          </mat-form-field>
        </div>
        <div class="m-3">
          <mat-form-field class="w-100">
            <input matInput type="password" formControlName="password" placeholder="Password">
            <mat-error *ngIf="password.invalid && submitted">Password is required</mat-error>
          </mat-form-field>
        </div>

      </form>
    </mat-dialog-content>
  </div>

  <div class="col-12">
    <mat-dialog-actions class="row mx-0">
      <div class="col-5">
        <button mat-raised-button type="button" (click)="onClose()" color="accent">Close</button>
      </div>
      <div class="col-5 offset-2 float-left">
        <button mat-raised-button type="submit" [disabled]="!loginForm.valid && loading" color="accent"
                (click)="onSubmit()">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Submit
        </button>
      </div>
    </mat-dialog-actions>

  </div>
</div>
