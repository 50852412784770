<div class="row mx-0 text-center tile-animation">
  <div style="height: 3vh"></div>
  <div class="row mx-0 text-center w-100">
    <div class="col-3">
      <div class="abstract-tile" style="animation: pulse 9s infinite alternate"></div>
    </div>
    <div class="col-3">
      <div class="abstract-tile" style="animation: pulse 5s infinite alternate"></div>
    </div>
    <div class="col-3">
      <div class="abstract-tile" style="animation: pulse 11s infinite alternate"></div>
    </div>
    <div class="col-3">
      <div class="abstract-tile" style="animation: pulse 8s infinite alternate"></div>
    </div>
    <div class="col-3">
      <div class="abstract-tile" style="animation: pulse 17s infinite alternate"></div>
    </div>
    <div class="col-3">
      <div class="abstract-tile" style="animation: pulse 7s infinite alternate"></div>
    </div>
    <div class="col-3">
      <div class="abstract-tile" style="animation: pulse 4s infinite alternate"></div>
    </div>
    <div class="col-3">
      <div class="abstract-tile" style="animation: pulse 13s infinite alternate"></div>
    </div>
  </div>

  <div class="col-12">
    <h4 class="taggie-title mt-3 mb-2">Congratulations. You just launched your Taggie.</h4>
  </div>
</div>
<hr>
<div class="row mx-0 text-center">
  <div class="col-12">
    <h4 class="my-2">Spread the Word!</h4>
    <a [routerLink]="['/start']" class="btn-standard">Get more Taggies! </a>

<!--    <p>Get more information here:</p>-->
<!--    <a [routerLink]="['/start']" class="btn-standard">Homepage</a>-->
  </div>
</div>
