import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../dataobjects/user';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {userRole} from '../dataobjects/userRole.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private defaultUser = new User(environment.publicUsername, environment.publicPassword, userRole.PUBLIC);

  constructor(private http: HttpClient) {
    if (sessionStorage.getItem('currentUser')) {
      this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
    } else {
      this.currentUserSubject = new BehaviorSubject<User>(this.defaultUser);
    }
    this.currentUser = this.currentUserSubject.asObservable();
    if (environment.debug) {
      console.log(this.currentUserValue);
    }
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  reset(): void {
    this.currentUserSubject.next(this.defaultUser);
    if (environment.debug) {
      console.log(this.currentUserValue);
    }

  }

  login(username: string, pass: string) {
    return this.http.post<boolean>(`${environment.apiUrl}admin/login`, {name: username, password: pass})
      .pipe(map(ans => {
        // login successful if there's a jwt token in the response
        if (ans) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          const user = {
            name: username,
            password: pass,
            role: userRole.ADMIN
          };
          sessionStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          if (environment.debug) {
            console.log(this.currentUserValue);
          }

        }
        return ans;
      }));
  }
  logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('currentUser');
    this.currentUserSubject.next(this.defaultUser);
    if (environment.debug) {
      console.log(this.currentUserValue);
    }

  }

}
