<div class="text-center" style="padding:10px" xmlns="http://www.w3.org/1999/html">
  <h1 class="taggie-title mat-display-2 mt-5 mb-4">FAQ:</h1>

  <h4 class="taggie-title">What is a tag?</h4>
  <p>Various contents are hidden behind a Taggie.
    "text", "chat", "a or b" describe the type of your Taggie.
    When people scan your Taggie, they are directed to this tag.
    The tag is the soul of your Taggie.
  </p>
  <h4 class="taggie-title">What is a "text" tag?</h4>
  <p>
    A "text" tag is the simplest form of a Taggie. You can write a short message, a poem or a short story on it.
    You have a total of 200 characters at your disposal.
    Therefore, choose your content wisely.
    People who scan your Taggie can only read it and not edit it.
  </p>
  <h4 class="taggie-title">Can I change my tag later?</h4>
  <p>
    At the moment you cannot edit your Taggie after you have launched it.
    In the future we want to make that possible, but until then you better think twice about what you post.
  </p>
  <h4 class="taggie-title"> Who can read my tag?</h4>
  <p>
    Everybody with a mobile device will be able to read your Taggie.
    That means everyone in the world, as long as they meet your Taggie.
  </p>
  <h4 class="taggie-title">Who is behind Taggie?</h4>
  <p>
    Taggie is built by a couple of students from Zurich, Switzerland working hard to make Taggie happen and accessible for everyone.
    If you want to give feedback, aks a question or just want to chat, reach out to us by mail: <a href = "mailto:taggiesocial@gmail.com">Send Mail</a>
  </p>

  <div class="col-12 text-center">
    <a [routerLink]="['/choose-tag']" class="btn-standard">Back</a>
  </div>

</div>
