import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-choose-tag',
  templateUrl: './choose-tag.component.html',
  styleUrls: ['./choose-tag.component.css'],

})
export class ChooseTagComponent implements OnInit {
  textTile = 'text';
  moreTile = 'more';
  faqTile = 'faq';
  chatTile = 'chat';
  binaryTile = 'binary';
  constructor() { }

  ngOnInit() {
  }

}
