import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-init-binary',
  templateUrl: './init-binary.component.html',
  styleUrls: ['./init-binary.component.css']
})
export class InitBinaryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
