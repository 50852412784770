import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-load-chat',
  templateUrl: './load-chat.component.html',
  styleUrls: ['./load-chat.component.css']
})
export class LoadChatComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
