<div class="col-12">
  <div style="padding: 5vw">
    <h5 class="taggie-title">Impressum</h5>
    <p>
      Taggie <br>
      c/o Jakob Tresch <br>
      Bederstrasse 76 <br>
      8002 Zürich <br>
      If you want to contact us:
      <a href="mailto:taggiesocial@gmail.com">Send Mail</a>
    </p>
  </div>
</div>
