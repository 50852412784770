<div class="row">
  <app-error [errorMessage]="errorMessage" [show]="errorOccurred"></app-error>
  <div class="col-12 text-center">
    <h3 class="taggie-title mt-3 mb-2">You just scanned a text tag!</h3>
  </div>
  <div class="col-12 px-4 text-center">
    <div class="text-tag">
      <div class="tile-space">
        <div [ngClass]="{
          'very-short-text': (tag.data.text.length > 0),
          'short-text': (tag.data.text.length > 10),
          'short-medium-text': (tag.data.text.length > 20),
          'medium-text': (tag.data.text.length > 50),
          'long-text': (tag.data.text.length > 80),
          'very-long-text': (tag.data.text.length > 120)}">{{tag.data.text}}</div>
      </div>
    </div>
    <div>Views: {{ views }}, created at: {{ createdAt | date :'MMM d, y - h:mm'}}</div>
  </div>
</div>
<app-loading [active]="loading"></app-loading>
