import { Injectable, isDevMode } from '@angular/core';
import { Text, TextData } from '../../dataobjects/tags/text';
import { TagService } from './tag.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TagType } from '../../dataobjects/tags/tag-type.enum';

@Injectable({
  providedIn: 'root'
})
export class TextService extends TagService {

  constructor(private http: HttpClient) {
    super();
    // example Ids for testing:
    // Unclaimed: 2CFf-TTCR-n3D2, n4bk-wLI4-tHOF, 4sLY-YSgF-ZIoJ
  }

  getTag(tagId: string, updateCounter: boolean): Observable<any> {
    // if we add '?t=r' we do not increase the counter.
    tagId = updateCounter && !isDevMode() ? tagId + '?t=' : tagId + '?t=r';
    return this.http.get(`${this.serverUrl}tag/${tagId}`,
      {
        responseType: 'json'
      });
  }

  claimTag(tag: Text): Observable<any> {
    return this.http.put(`${this.serverUrl}tag/claim/${tag.tagId}`,
      tag,
      {
        responseType: 'json'
      });
  }

  emptyTag(): Text {
    return {
      data: new TextData(),
      tagId: '',
      occupied: true,
      type: TagType.SimpleTextfield
    };
  }
}
