import { Tag } from './tag';
import { TagType } from './tag-type.enum';

export class TextData {
  text: string;
}

export class Text implements Tag {
  data: TextData;
  tagId: string;
  occupied: boolean;
  type: TagType;

  constructor() {
    this.type = TagType.SimpleTextfield;
    this.data = new TextData();
    this.occupied = false;
  }

  toString(): string {
    return this.data.text;
  }
}
