import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../authentication.service';
import {LoginDialogComponent} from '../../components/login/login-dialog/login-dialog.component';
import {environment} from '../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {userRole} from '../../dataobjects/userRole.enum';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser.role === userRole.ADMIN) {
      // authorised so return true
      return true;
    }
    this.openDialog();
    return false;
  }

  openDialog(): void {

    const dialogRef = this.dialog.open(LoginDialogComponent, {
      autoFocus: true,
      maxWidth: '310px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (environment.debug) {
        console.log('The dialog was closed');
      }
      if (!result) {
        if (environment.debug) {
          console.log('Login failed, return');
        }
        this.router.navigate(['../'], {relativeTo: this.route}).then();
      } else {
        this.router.navigate(['/admin']).then();
      }
    });
  }

}
