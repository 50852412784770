import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TagService {

  serverUrl = environment.apiUrl;

  constructor() { }

  getAuthString(): string {
    const auth = btoa(`${environment.publicUsername}:${environment.publicPassword}`);
    return `Basic ${auth}`;
  }
}
