<div class="row mx-0 text-center" *ngIf="show">
  <div class="col-12">
    <div class="col-10 offset-1">
      <div class="error-message">
        <p><b>An error occurred: </b></p>
        <p>{{ errorMessage }}</p>
      </div>
    </div>
  </div>
</div>


