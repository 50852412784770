import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TextService } from '../../services/tags/text.service';
import { LsKeys } from '../utility/ls-keys.enum';

@Component({
  selector: 'app-scan',
  templateUrl: './scan.component.html',
  styleUrls: ['./scan.component.css']
})
export class ScanComponent implements OnInit {

  loading: boolean;

  constructor(public textService: TextService, private route: ActivatedRoute, private router: Router) {
    this.loading = true;
  }

  ngOnInit() {
    localStorage.clear(); // TODO: this clears the whole localStorage. Maybe not what we want?
    const tagId = String(this.route.snapshot.paramMap.get('tagId'));
    this.handleTagId(tagId);
  }

  handleTagId(tagId: string): void {
    this.textService.getTag(tagId, false).subscribe((data) => {
      // confirmed that the tagId is valid. Add it to localStorage.
      localStorage.setItem(LsKeys.TagId, tagId);
      // tag is not already occupied. Continue in this choose-tag
      if (!data.occupied) {
        this.router.navigate(['/choose-tag']).then();
      } else {
        // Tag exists, so go to the loading page
        this.router.navigate(['/load-text']).then();
      }
      this.loading = false;
    }, ((error) => {
      this.loading = false;
      this.router.navigate(['/start']).then();
    }));
  }
}
