import { Component, OnInit} from '@angular/core';
import { Text } from '../../../dataobjects/tags/text';
import { LsKeys } from '../../utility/ls-keys.enum';

@Component({
  selector: 'app-init-text',
  templateUrl: './init-text.component.html',
  styleUrls: ['./init-text.component.css']
})
export class InitTextComponent implements OnInit {
  textAreaInput: string;
  tag: Text;
  textTile = 'text';

  constructor() {
  }

  ngOnInit() {
    this.tag = this.load();
    if (this.tag == null) { // Tag not found in localStorage
      this.tag = new Text();
      this.tag.tagId = localStorage.getItem(LsKeys.TagId);
      this.textAreaInput = '';
    } else { // Tag found in localStorage, pre-fill the textArea.
      this.textAreaInput = this.tag.data.text;
    }
  }

  save(): void {
    this.tag.data.text = this.textAreaInput;
    localStorage.setItem(LsKeys.SimpleTextField, JSON.stringify(this.tag));
  }

  load(): Text {
    return JSON.parse(localStorage.getItem(LsKeys.SimpleTextField));
  }

}
