import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../authentication.service';
import {environment} from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const currentUser = this.authenticationService.currentUserValue;
    if (environment.debug) {
      console.log(currentUser);
    }
    if (currentUser && currentUser.name && currentUser.password) {
      if (environment.debug) {
        console.log('Added auth header');
      }
      request = request.clone({
        setHeaders: {
          Authorization: this.getAuthString(currentUser.name, currentUser.password)
        }
      });
    }

    return next.handle(request);
  }

  getAuthString(name: string, pass: string): string {
    const auth = btoa(`${name}:${pass}`);
    return `Basic ${auth}`;
  }

}
