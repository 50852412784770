import { Component, OnInit } from '@angular/core';
import { Text } from '../../../dataobjects/tags/text';
import { TextService } from '../../../services/tags/text.service';
import { Router } from '@angular/router';
import {LsKeys} from '../../utility/ls-keys.enum';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-preview-text',
  templateUrl: './preview-text.component.html',
  styleUrls: ['./preview-text.component.css']
})
export class PreviewTextComponent implements OnInit {

  tag: Text;
  loading: boolean;
  errorOccurred: boolean;
  errorMessage: string;

  constructor(public textService: TextService, private router: Router) {
    this.loading = false;
    this.errorOccurred = false;

    this.tag = this.load();
    if (environment.debug) {
      console.log('Preview: ' + JSON.stringify(this.tag));
    }
  }

  ngOnInit() {
  }

  send(): void {
    this.loading = true; // loading circle starts.
    this.textService.claimTag(this.tag).subscribe((data) => { // successful callback
        if (data == null) { // TODO: what if this is null?
          this.errorOccurred = true;
          this.errorMessage = 'The Taggie! could not be sent.';
        } else {
          this.router.navigate(['/success']).then(r => {
            localStorage.removeItem(LsKeys.SimpleTextField);
          });
        }
        this.loading = false;
      }, (error) => {                              // Error callback
        // console.error('Error caught in component: ' + JSON.stringify(error));
        this.errorOccurred = true;
        this.errorMessage = ' "' + error.error.message + '"';
        this.loading = false;
      }
    );
  }

  load(): Text {
    const lsItem = localStorage.getItem(LsKeys.SimpleTextField);
    if (lsItem === null) {
      this.errorMessage = 'Error occurred while loading the tag. Please try again.';
      this.errorOccurred = true;
      return this.textService.emptyTag();
    } else {
      return JSON.parse(lsItem);
    }
  }

}
