import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FaqComponent} from './components/faq/faq.component';
import {HeaderComponent} from './components/header/header.component';
import {LoadBinaryComponent} from './components/load-tag/load-binary/load-binary.component';
import {LoadTextComponent} from './components/load-tag/load-text/load-text.component';
import {LoadChatComponent} from './components/load-tag/load-chat/load-chat.component';
import {InitChatComponent} from './components/init-tag/init-chat/init-chat.component';
import {InitBinaryComponent} from './components/init-tag/init-binary/init-binary.component';
import {InitTextComponent} from './components/init-tag/init-text/init-text.component';
import {ChooseTagComponent} from './components/init-tag/choose-tag/choose-tag.component';
import {ScanComponent} from './components/scan/scan.component';
import {HomeComponent} from './components/home/home.component';
import {BackButtonComponent} from './components/utility/back-button/back-button.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SuccessComponent} from './components/success/success.component';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {LoadingComponent} from './components/utility/loading/loading.component';
import {PreviewTextComponent} from './components/preview-tag/preview-text/preview-text.component';
import {PreviewBinaryComponent} from './components/preview-tag/preview-binary/preview-binary.component';
import {PreviewChatComponent} from './components/preview-tag/preview-chat/preview-chat.component';
import {StartComponent} from './components/start/start.component';
import {ErrorComponent} from './components/utility/error/error.component';
import {TileComponent} from './components/tile/tile.component';
import {FooterComponent} from './components/footer/footer.component';
import {ImpressumComponent} from './components/impressum/impressum.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {LoginDialogComponent} from './components/login/login-dialog/login-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {AdminPanelComponent} from './components/admin-panel/admin-panel.component';
import {AuthInterceptor} from './services/interceptors/auth.interceptor';
import {ErrorInterceptor} from './services/interceptors/error.interceptor';
import {AlertComponent} from './components/alert/alert.component';

@NgModule({
  declarations: [
    AppComponent,
    FaqComponent,
    HeaderComponent,
    LoadBinaryComponent,
    LoadTextComponent,
    LoadChatComponent,
    InitChatComponent,
    InitBinaryComponent,
    InitTextComponent,
    ChooseTagComponent,
    ScanComponent,
    HomeComponent,
    BackButtonComponent,
    SuccessComponent,
    LoadingComponent,
    PreviewTextComponent,
    PreviewBinaryComponent,
    PreviewChatComponent,
    ErrorComponent,
    TileComponent,
    StartComponent,
    ImpressumComponent,
    FooterComponent,
    LoginDialogComponent,
    AdminPanelComponent,
    AlertComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatDialogModule,
    MatCheckboxModule,
    NgbModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true}
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    LoginDialogComponent
  ]
})
export class AppModule {
}
