<div class="row mx-0 text-center">
  <h1 class="taggie-title display-4 mb-4 mt-3">You scanned a new Taggie.</h1>
  <div class="col-12 text-center">
    <h5 class="taggie-title">Choose your tag:</h5>
  </div>

  <div class="col-6">
    <app-tile [routerLink]="['/new-text']" [tileType]="textTile" [tileShadow]=true></app-tile>
  </div>

  <div class="col-6">
    <app-tile [routerLink]="['/faq']" [tileType]="faqTile" [tileShadow]=true></app-tile>
  </div>

  <div class="col-12">
    <h5 class="taggie-title mt-2 mb-3">
      More tags will soon be available.
    </h5>
  </div>


  <div class="col-4 offset-2">
    <app-tile [tileType]="chatTile" [tileShadow]=false></app-tile>
  </div>
  <div class="col-4">
    <app-tile [tileType]="binaryTile" [tileShadow]=false></app-tile>
  </div>

</div>
